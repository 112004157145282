import { TextField } from "@mui/material";
import { useEffect } from "react";
import { StateGlobal } from "../../context/GlobalContext";

export function InputDate({ slug, disabled, size }) {
  const { body_query, setBody_query } = StateGlobal();

  return (
    <TextField
      size={size}
      type="date"
      value={body_query[slug] ? body_query[slug] : ""}
      onChange={(e) => setBody_query({ ...body_query, [slug]: e.target.value })}
    />
  );
}
