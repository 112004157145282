import * as C from "./styles";
import { useEffect } from "react";
import {
  CircularProgress,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import React, { useState } from "react";
import ButtonDark from "../../components/ButtonDark";
import { MoreVertical, Trash } from "react-feather";
import { StateGlobal } from "../../context/GlobalContext";
import api from "../../service/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function AccessTokens(props) {
  const [isLoading, setIsLoading] = useState(false);
  const [data_token, setData_token] = useState([]);
  const { dataUser } = StateGlobal();
  const MySwal = withReactContent(Swal);

  const [token_id, setToken_id] = useState("");

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleGetTokens() {
    try {
      setIsLoading(true);
      const res = await api.post("/api/user/admin/permission/tokens", {
        user_id: dataUser.id,
      });
      setIsLoading(false);

      setData_token(res.data);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleNewToken() {
    try {
      setIsLoading(true);
      const res = await api.post("/api/user/admin/permission/tokens/register", {
        user_id: dataUser.id,
      });
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      setIsLoading(false);
      handleGetTokens();
      setAnchorEl(null);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDeleteToken() {
    try {
      setIsLoading(true);
      const res = await api.delete(
        `/api/user/admin/permission/tokens/delete/${dataUser.id}/${token_id}`
      );
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 2000,
      });

      handleClose();
      setIsLoading(false);
      handleGetTokens();
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    handleGetTokens();
  }, []);

  return (
    <C.Container>
      <C.ButtonAndTitle>
        <C.Title>Tokens de acesso via API</C.Title>
        <C.ContainerButton>
          <ButtonDark text="Gerar um novo token" funcao={handleNewToken} />
        </C.ContainerButton>
      </C.ButtonAndTitle>
      <C.Content>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            {isLoading === true ? (
              <C.DivLoading>
                <CircularProgress
                  style={{
                    width: "35px",
                    height: "35px",
                    color: "#0F2554",
                  }}
                />
              </C.DivLoading>
            ) : (
              <>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F3F2F7" }}>
                    <TableCell align="center">Data de Criação</TableCell>
                    <TableCell align="center">Token de acesso</TableCell>
                    <TableCell align="center">Opções</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data_token?.map((row) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={row.id}
                    >
                      <TableCell align="center">
                        {row.create_date} - {row.create_hour}
                      </TableCell>
                      <TableCell align="center">{row.token}</TableCell>
                      <TableCell align="center">
                        {" "}
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertical
                              strokeWidth={1}
                              onClick={() => setToken_id(row.id)}
                            />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem onClick={() => handleDeleteToken()}>
                              <Trash
                                width={20}
                                style={{ marginRight: "10px" }}
                              />
                              Excluir Token
                            </MenuItem>
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </C.Content>
    </C.Container>
  );
}
