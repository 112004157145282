import * as C from "./styles";
import { useEffect, useState } from "react";
import logo from "../../assets/images/deep_logo.png";
// import { CircularProgress } from "@mui/material";
import ButtonLight from "../ButtonLight";
import { Select, MenuItem } from "@mui/material";
import ButtonDark from "../ButtonDark";
import { StateGlobal } from "../../context/GlobalContext";
import api from "../../service/api";

export default function LeftMenu({ name_user }) {
  const [isLoading, setIsLoading] = useState(false);
  const [blockDatasource, setBlockDatasource] = useState(true);
  const [blockQuerys, setBlockQuerys] = useState(true);
  const { setComponents, setInfo_query, setBody_query } = StateGlobal();
  const [data_datasource, setData_datasource] = useState([]);
  const [data_querys, setData_querys] = useState([]);
  const [datasource, setDatasource] = useState("");
  const [query, setQuery] = useState("");
  const [block_search, setBlock_search] = useState(true);


  function handleCreateUser() {
    setComponents(2);
  }

  async function getDatasources() {
    try {
      const res = await api.get("/api/user/datasources");
      setData_datasource(res.data);
      setBlockDatasource(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function getQuerys(e) {
    setDatasource(e.target.value);
    try {
      const res = await api.post("/api/user/queries", {
        datasource_id: e.target.value,
      });
      setData_querys(res.data);
      setBlockQuerys(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleRequest() {
    setBody_query({});
    setIsLoading(true)
    setBlock_search(true)
    try {
      const res = await api.post("/api/user/queries/filters", {
        query_id: query,
      });
      if (res.data) {
        setIsLoading(false)
        setBlock_search(false)
        setInfo_query(res.data);
        setComponents(1);
      }
    } catch (err) {
      console.log(err);
      setIsLoading(false)
      setBlock_search(false)

    }
  }

  function VerifyDisabled() {
    if (datasource !== "" && query !== "") {
      setBlock_search(false);
    } else {
      setBlock_search(true);
    }
  }

  useEffect(() => {
    VerifyDisabled();
  }, [datasource, query]);

  useEffect(() => {
    getDatasources();
  }, []);


  return (
    <C.LeftMenu>
      <>
        <C.Header>
          <img alt="profile" src={logo} />
          <>
            {name_user === '' ? <C.TitleHeader>Carregando...</C.TitleHeader> :
              <C.TitleHeader>Olá, {name_user}!</C.TitleHeader>}

          </>
        </C.Header>
        <C.Content>
          <C.ContentButton>
            <Select
              id="meet"
              size="small"
              disabled={blockDatasource}
              style={{
                border: "1px solid #d8d6de",
                width: "100%",
                backgroundColor: "#FFF",
              }}
              value={datasource}
              onChange={(e) => getQuerys(e)}
            >
              {data_datasource.map((data_datasource) => (
                <MenuItem key={data_datasource.id} value={data_datasource.id}>
                  {data_datasource.name}
                </MenuItem>
              ))}
            </Select>
          </C.ContentButton>
          <C.ContentButton>
            <Select
              id="meet"
              size="small"
              disabled={blockQuerys}
              style={{
                border: "1px solid #d8d6de",
                width: "100%",
                backgroundColor: "#FFF",
              }}
              value={query}
              onChange={(e) => setQuery(e.target.value)}
            >
              {data_querys.map((data_querys) => (
                <MenuItem key={data_querys.id} value={data_querys.id}>
                  {data_querys.name}
                </MenuItem>
              ))}
            </Select>
          </C.ContentButton>
          <C.ContentButton>
            <ButtonLight
              text={isLoading ? 'Carregando...' : 'Selecionar Consulta'}
              funcao={handleRequest}
              disabled={block_search}
            />
          </C.ContentButton>
          <C.ContentButton>
            <ButtonDark text="Usuários de acesso" funcao={handleCreateUser} />
          </C.ContentButton>
        </C.Content>
      </>
      {/* )} */}
    </C.LeftMenu>
  );
}
