import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: row;
`;

export const RightContainer = styled.div`
  background-color: ${({ theme }) => theme.colors.red};
  width: 100%;
`;

export const Icon = styled.button`
  background-color: #000;
  color: #fff;
  border: none;
  border-radius: 0.625rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.3125rem 1.5625rem;
  position: absolute;
  top: 1.875rem;
  right: 1.875rem;
  cursor: pointer;
`;
