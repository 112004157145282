import * as C from "./styles";
import { useEffect } from "react";

import {
  CircularProgress,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  IconButton,
  MenuItem,
  Menu,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import ButtonDark from "../ButtonDark";
import { MoreVertical, Trash } from "react-feather";
import { StateGlobal } from "../../context/GlobalContext";
import api from "../../service/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Querys() {
  const MySwal = withReactContent(Swal);

  const [isLoading, setIsLoading] = useState(false);
  const [query_block, setQuery_block] = useState(true);
  const [button_block, setButton_block] = useState(true);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [datasource, setDatasource] = useState("");
  const [options_datasource, setOptions_datasource] = useState([]);
  const [options_query, setOptions_query] = useState([]);

  const [query_id, setQuery_id] = useState('')

  const [query_permissions, setQuery_permissions] = useState([]);
  const [query, setQuery] = useState("");
  const { dataUser } = StateGlobal();

  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleGetQuerys() {
    try {
      setIsLoading(true);
      const res = await api.post("/api/user/admin/permission/queries", {
        user_id: dataUser.id,
      });
      setQuery_permissions(res.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDeleteQuery() {
    try {
      setIsLoading(true);
      const res = await api.delete(
        `/api/user/admin/permission/queries/delete/${dataUser.id}/${query_id}`
      );
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      handleClose();
      setIsLoading(false);
      handleGetQuerys();
    } catch (err) {
      console.log(err);
    }
  }

  async function handleGetDatasourcesSelect() {
    try {
      setIsLoading(true);
      const res = await api.get("/api/user/datasources");
      setOptions_datasource(res.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleGetQuerysSelect(e) {
    setDatasource(e.target.value);
    try {
      const res = await api.post("/api/user/admin/permission/queries/show", {
        datasource_id: e.target.value,
      });
      setOptions_query(res.data);
      setQuery_block(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAddQueryPermission() {
    try {
      setIsLoading(true);
      const res = await api.post(
        "/api/user/admin/permission/queries/register",
        {
          user_id: dataUser.id,
          query_id: query,
          datasource_id: datasource,
        }
      );
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      setIsLoading(false);
      handleGetQuerys();
      if (res.data.error === "false") {
        setQuery("");
        setDatasource("");
      }
    } catch (err) {
      console.log(err);
    }
  }

  function VerifyDisabled() {
    if (datasource !== "" && query !== "") {
      setButton_block(false);
    } else {
      setButton_block(true);
    }
  }

  useEffect(() => {
    VerifyDisabled();
  }, [datasource, query]);

  useEffect(() => {
    handleGetQuerys();
    handleGetDatasourcesSelect();
  }, []);

  return (
    <C.Content>
      <C.DivRow>
        <C.MediumInput>
          <Select
            size="small"
            style={{ width: "500px" }}
            value={datasource}
            onChange={(e) => handleGetQuerysSelect(e)}
          >
            {options_datasource.map((options_datasource) => (
              <MenuItem
                key={options_datasource.id}
                value={options_datasource.id}
              >
                {options_datasource.name}
              </MenuItem>
            ))}
          </Select>
        </C.MediumInput>
        <C.MediumInput>
          <Select
            size="small"
            style={{ width: "500px" }}
            disabled={query_block}
            value={query}
            onChange={(e) => setQuery(e.target.value)}
          >
            {options_query.map((options_query) => (
              <MenuItem key={options_query.id} value={options_query.id}>
                {options_query.name}
              </MenuItem>
            ))}
          </Select>
        </C.MediumInput>
        <C.ContainerButton>
          <ButtonDark
            text="Adicionar"
            funcao={handleAddQueryPermission}
            disabled={button_block}
          />
        </C.ContainerButton>
      </C.DivRow>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          {isLoading === true ? (
            <C.DivLoading>
              <CircularProgress
                style={{
                  width: "35px",
                  height: "35px",
                  color: "#0F2554",
                }}
              />
            </C.DivLoading>
          ) : (
            <>
              <TableHead>
                <TableRow style={{ backgroundColor: "#F3F2F7" }}>
                  <TableCell align="center">Datasource</TableCell>
                  <TableCell align="center">Consulta</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {query_permissions.map((row) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={row.id}
                  >
                    <TableCell align="center">{row.datasource}</TableCell>
                    <TableCell align="center">{row.consulta}</TableCell>
                    <TableCell align="center">
                      {" "}
                      <div>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertical
                            strokeWidth={1}
                            onClick={() => {
                              setQuery_id(row.id)
                            }}
                          />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem onClick={() => handleDeleteQuery()}>
                            <Trash width={20} style={{ marginRight: "10px" }} />
                            Excluir
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </C.Content>
  );
}
