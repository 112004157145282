import styled from "styled-components";

export const Container = styled.div`
  height: 100%;
  background-color: ${({ theme }) => theme.colors.primary};
`;

export const PositionBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
`;

export const Box = styled.div`
  display: flex;
  width: 1000px;
  height: 600px;
`;

export const FormData = styled.div`
  width: 400px;
`;

export const SectionImg = styled.div`
  width: 400px;
  height: 100%;
  border-radius: 50px 0px 0px 50px;
`;

export const BannerImg = styled.img`
  height: 600px;
  border-radius: 50px 0px 0px 50px;
`;

export const SectionInfor = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.white};
  height: 100%;
  width: 100%;
  border-radius: 0px 50px 50px 0px;
`;

export const InputAndLabel = styled.div`
  display: flex;
  flex-direction: column;

  div {
    display: flex;
    justify-content: space-between;
    margin-bottom: 0px;
  }

  p {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 0px;
    margin-top: 20px;
  }

  label {
    margin-top: 20px;
    margin-bottom: 2px;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.text_login};
  }
`;

export const AlignInfoSection = styled.div`
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

export const SectionPhrase = styled.div`
  p {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const BtnSendInfo = styled.button`
  border: none;
  background-color: ${({ theme }) => theme.colors.primary};
  color: ${({ theme }) => theme.colors.white};
  width: 250px;
  height: 45px;
  border-radius: 20px;
  font-size: 17px;
  font-weight: 600;
  box-shadow: rgb(83 83 83 / 79%) 0px 3px 3px 0px;
  margin-top: 30px;
  cursor: pointer;
`;
