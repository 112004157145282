import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.colors.white_secondary};
  width: 100%;
  padding: 80px 50px;
`;

export const Header = styled.div``;

export const Logo = styled.div`
  position: fixed;
  top: 20px;
  right: 20px;
`;

export const Title = styled.p`
  font-weight: 500;
  font-size: 35px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey};
  margin-bottom: 30px;
  margin-left: 0px;
`;

export const Text = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.black};
  margin-bottom: 20px;
`;

export const TextBoldHeader = styled.p`
  font-weight: 500;
  font-size: 17px;
  line-height: 21px;
  margin: 0;
  color: ${({ theme }) => theme.colors.primary};
`;

export const Content = styled.div`
  margin-top: 1%;
  display: flex;
  flex-direction: column;
`;

export const TitleContent = styled.p`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${({ theme }) => theme.colors.grey};
  cursor: pointer;
  margin: 4% 0px 5px 5px;
`;

export const Underline = styled.div`
  margin-top: 5%;
  border: 1px solid #07254f;
  width: 100%;
  margin-bottom: 25px;
`;

export const Alert = styled.div`
  width: 100%;
  height: auto;
  background-color: #fd5454;
  padding: 15px 15px;
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;

  p {
    margin: 0;
    font-weight: 600;
    font-size: 13px;
    line-height: 21px;
  }
`;

export const Avatar = styled.div`
  margin-top: 25px;
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  border-radius: 50%;
`;

export const InputAndLabel = styled.div`
  display: flex;
  flex-direction: column;

  label {
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    color: ${({ theme }) => theme.colors.black};
    margin-bottom: 2px;
  }
`;
export const SmallInput = styled.div`
  width: 24%;
  margin-right: 2%;
`;

export const MediumInput = styled.div`
  margin-top: 20px;
  margin-bottom: 20px;
  width: 50%;
`;

export const DivRow = styled.div`
  display: flex;
  justify-content: flex-start;
`;

export const Export = styled.div`
  border: 2px solid #041e42;
  border-radius: 10px;
  display: flex;
  align-items: center;
  margin-top: 25px;
  width: 250px;

  span {
    margin: 0px 0px;
    padding: 3% 3%;
    font-weight: 500;
    font-size: 15px;
    line-height: 21px;
    color: ${({ theme }) => theme.colors.primary};
    cursor: pointer;
  }
`;

export const ContainerButtons = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Buttons = styled.div`
  width: 100%;
  max-width: 200px;
  margin-top: 8%;
`;

export const DivLoading = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 20px;
`;

export const ContainerButton = styled.div`
  width: 100%;
  max-width: 200px;
  margin-top: 40px;
  margin-right: 20px;
`;

export const ButtonAndTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const Form = styled.div`
  width: 100%;
  max-width: 1350px;
`;
