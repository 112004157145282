import React, { useState } from "react";
import * as C from "./styles";
import bannerLogin from "../../assets/imgs/bannerLogin.svg";
import LogoLogin from "../../assets/imgs/LogoLogin.svg";
import { TextField } from "@mui/material";
import { useNavigate } from "react-router";
import api from "../../service/api";
import { saveToken, setFirstLogin } from "../../service/auth";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Login() {
  const navigate = useNavigate();
  const MySwal = withReactContent(Swal);
  const [mailClient, setMailClient] = useState("");
  const [passWordClient, setPassWordClient] = useState("");

  async function handleSubmit() {
    if (passWordClient?.length < 6) {
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: "warning",
        title: "A senha precisa conter no mínimo 6 caracteres",
        showConfirmButton: true,
      });
    }
    try {
      if (passWordClient?.length >= 6) {
        const res = await api.post("/api/user/login", {
          email: mailClient,
          password: passWordClient,
        });
        saveToken(res.data?.token);

        if (res.data.error === "true") {
          MySwal.fire({
            heightAuto: false,
            position: "center",
            icon: "error",
            title: res.data.message,
            showConfirmButton: false,
            timer: 2000,
          });
        }

        if (res.data.error === "false") {
          setTimeout(() => {
            setFirstLogin(true)
            navigate("/home");
          }, [1000]);
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <C.Container>
      <C.PositionBox>
        <C.Box>
          <C.SectionImg>
            <C.BannerImg alt="bannerLogin" src={bannerLogin} />
          </C.SectionImg>
          <C.SectionInfor>
            <C.AlignInfoSection>
              <div>
                <img alt="LogoLogin" src={LogoLogin} />
              </div>
              <C.SectionPhrase>
                <p>Bem vindo a nossa plataforma 👋🏻</p>
              </C.SectionPhrase>
              <C.FormData>
                <C.InputAndLabel>
                  <label id="email">E-mail</label>
                  <TextField
                    size="small"
                    placeholder="exemplo@gmail.com"
                    value={mailClient}
                    onChange={(e) => {
                      setMailClient(e.target.value);
                    }}
                  />
                </C.InputAndLabel>

                <C.InputAndLabel>
                  <label id="email">Senha</label>
                  <TextField
                    size="small"
                    placeholder="******"
                    type="password"
                    value={passWordClient}
                    onChange={(e) => {
                      setPassWordClient(e.target.value);
                    }}
                  />
                </C.InputAndLabel>

                <C.BtnSendInfo
                  onClick={(e) => {
                    handleSubmit();
                  }}
                >
                  Entrar
                </C.BtnSendInfo>
              </C.FormData>
            </C.AlignInfoSection>
          </C.SectionInfor>
        </C.Box>
      </C.PositionBox>
    </C.Container>
  );
}
