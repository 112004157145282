import * as C from "./styles";
import ButtonDark from "../../components/ButtonDark";
import ButtonGreen from "../../components/ButtonGreen";
import { InputSelect } from "../../components/InputSelect";
import { InputText } from "../../components/InputText";
import { InputDate } from "../../components/InputDate";
import { StateGlobal } from "../../context/GlobalContext";
import { CircularProgress } from "@mui/material";
import api from "../../service/api";
import { useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Companies() {
  const MySwal = withReactContent(Swal);
  const [isLoading, setIsLoading] = useState(false);
  const { info_query, body_query } = StateGlobal();
  const [has_result, setHas_result] = useState(false);
  const [result, setResult] = useState("");
  const [csv, setCsv] = useState("");

  async function sendBodyQuery() {
    setHas_result(false);
    setIsLoading(true)
    try {
      let body = [];
      for (var [key, value] of Object.entries(body_query)) {
        body = [...body, { slug: key, value }];
      }

      const res = await api.post(`api/user/queries/result`, {
        query_id: info_query.query.id,
        filters: body,
      });
      if (res.data.error === false) {
        setResult(res.data?.registros);
        setCsv(res.data?.csv);
        setIsLoading(false)
        setHas_result(true);
      }
      if (res.data.error === true) {
        MySwal.fire({
          heightAuto: false,
          position: "center",
          icon: "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
      }
      setIsLoading(false)

    } catch (error) {
      console.log(error);
    }
  }

  return (
    <C.Container>
      <C.Title>
        {info_query?.query?.identifier_code} - {info_query?.query?.name}
      </C.Title>
      <C.Header>
        <C.DivRow>
          {info_query?.filters?.map((info_query) =>
            info_query?.type === "date" ? (
              <C.SmallInput>
                <C.InputAndLabel>
                  <label>{info_query?.label}</label>
                  <InputDate size="small" slug={info_query?.name} />
                </C.InputAndLabel>
              </C.SmallInput>
            ) : info_query?.type === "text" ? (
              <C.SmallInput>
                <C.InputAndLabel>
                  <label>{info_query?.label}:</label>
                  <InputText size="small" slug={info_query?.name} />
                </C.InputAndLabel>
              </C.SmallInput>
            ) : (
              <C.SmallInput>
                <C.InputAndLabel>
                  <label>{info_query?.label}:</label>
                  <InputSelect
                    options={
                      body_query === "object"
                        ? [{ label: "selecione", value: "" }]
                        : info_query?.options_source
                    }
                    slug={info_query?.name}
                  />
                </C.InputAndLabel>
              </C.SmallInput>
            )
          )}
        </C.DivRow>
        <C.ContainerButton>
          <ButtonDark text="Processar Resultados" funcao={sendBodyQuery} disabled={isLoading} />
        </C.ContainerButton>
        <C.Underline />
      </C.Header>
      {isLoading === true &&
        <C.DivLoading>
          <CircularProgress
            style={{
              width: "35px",
              height: "35px",
              color: "#0F2554",
            }}
          />
        </C.DivLoading>}
      {has_result === true ? (

        <>
          <C.Content>
            <C.Text> Resultados das pesquisas:</C.Text>
            <C.Text> Total de registros: {result} registros encontrados</C.Text>
          </C.Content>
          <C.ContainerButton>
            <ButtonGreen
              text="Baixar planilha de resultados"
              funcao={() => window.open(csv)}
            />
          </C.ContainerButton>
        </>
      ) : (
        ""
      )}
    </C.Container>
  );
}
