import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import { useNavigate } from "react-router-dom";

const StyledButton = styled(Button)(({ theme }) => ({
  borderRadius: "10px",
  width: "100%",
  height: "40px",
  fontSize: "16px",
  fontWeight: 500,
  textTransform: "initial",
  color: "#07254F",
  borderColor: "#07254F",
  borderWidth: "2px",

  // "@media (max-width:660px)": {
  //   width: "160px",
  // },
}));

export default function OutlinedButton({ text, route, funcao }) {
  const navigate = useNavigate();

  return (
    <StyledButton
      variant="outlined"
      onClick={() => {
        if (funcao) {
          funcao();
        } else {
          navigate(`${route}`);
        }
      }}
    >
      {text}
    </StyledButton>
  );
}
