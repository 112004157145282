import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import { GlobalStyle } from "./assets/global";
import ContextProvider from "./context/GlobalContext";
import theme from "./assets/themes/theme";
import Login from "./pages/Login";
import Home from "./pages/Home";

function App() {
  return (
    <>
      <ContextProvider>
        <ThemeProvider theme={theme}>
          <GlobalStyle />
          <Router>
            <Routes>
              <Route exact path="/" element={<Login />} />
              <Route exact path="/home" element={<Home />} />
            </Routes>
          </Router>
        </ThemeProvider>
      </ContextProvider>
    </>
  );
}

export default App;
