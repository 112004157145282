import * as C from "./styles";
import { TextField, Select, MenuItem } from "@mui/material";
import ButtonDark from "../../components/ButtonDark";
import ButtonOutlined from "../../components/ButtonOutlined";
import { StateGlobal } from "../../context/GlobalContext";
import api from "../../service/api";
import Swal from "sweetalert2";
import { PhoneMask } from '../../components/Masks'
import withReactContent from "sweetalert2-react-content";
const option_user = [
  {
    label: "Administrador",
    value: "admin",
  },
  {
    label: "Colaborador",
    value: "collaborator",
  },
  {
    label: "Cliente",
    value: "client",
  },
];

export default function CreateUsers() {
  const { dataUser, setDataUser, setComponents } = StateGlobal();
  const MySwal = withReactContent(Swal);
  function handleGetUsers() {
    setComponents(2);
  }

  async function handleSendData() {
    if (dataUser.password.length < 6) {
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: "warning",
        title: "A senha precisa conter no mínimo 6 caracteres",
        showConfirmButton: true,
      });
    }
    try {
      if (
        dataUser.password !== "" &&
        dataUser.password !== null &&
        dataUser.password !== undefined &&
        dataUser.password.length >= 6
      ) {
        const res = await api.post("api/user/admin/register", {
          name: dataUser.name,
          company_name: dataUser.company_name,
          user_type: dataUser.user_type,
          phone: dataUser.phone?.replace(/[^0-9s]/g, ""),
          email: dataUser.email,
          password: dataUser.password,
        });

        MySwal.fire({
          heightAuto: false,
          position: "center",
          icon: res.data.error === "false" ? "success" : "error",
          title: res.data.message,
          showConfirmButton: false,
          timer: 2000,
        });
        if (res.data.error === "false") {
          handleGetUsers();
        }
      }
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <C.Container>
      <C.Title>Cadastro do Usuário</C.Title>
      <C.Content>
        <C.Form>
          <C.MediumInput>
            <C.InputAndLabel>
              <label>Empresa</label>
              <TextField
                fullWidth={true}
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={dataUser?.company_name}
                onChange={(e) => {
                  setDataUser({
                    ...dataUser,
                    company_name: e.target.value,
                  });
                }}
              />
            </C.InputAndLabel>
          </C.MediumInput>
          <C.MediumInput>
            <C.InputAndLabel>
              <label>Nome</label>
              <TextField
                fullWidth={true}
                size="small"
                id="outlined-basic"
                variant="outlined"
                value={dataUser?.name}
                onChange={(e) => {
                  setDataUser({
                    ...dataUser,
                    name: e.target.value,
                  });
                }}
              />
            </C.InputAndLabel>
          </C.MediumInput>
          <C.DivRow>
            <C.SmallInput>
              <C.InputAndLabel>
                <label>Telefone</label>
                <TextField
                  fullWidth={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={dataUser?.phone ? PhoneMask(dataUser.phone) : dataUser.phone}
                  onChange={(e) => {
                    setDataUser({
                      ...dataUser,
                      phone: e.target.value,
                    });
                  }}
                />
              </C.InputAndLabel>
            </C.SmallInput>
            <C.SmallInput>
              <C.InputAndLabel>
                <label>E-mail</label>
                <TextField
                  fullWidth={true}
                  size="small"
                  id="outlined-basic"
                  variant="outlined"
                  value={dataUser?.email}
                  onChange={(e) => {
                    setDataUser({
                      ...dataUser,
                      email: e.target.value,
                    });
                  }}
                />
              </C.InputAndLabel>
            </C.SmallInput>
          </C.DivRow>
          <C.MediumInput>
            <C.InputAndLabel>
              <label>Tipo de Usuário</label>
              <Select
                size="small"
                value={dataUser.user_type}
                onChange={(e) =>
                  setDataUser({
                    ...dataUser,
                    user_type: e.target.value,
                  })
                }
              >
                {option_user.map((option_user) => (
                  <MenuItem key={option_user.value} value={option_user.value}>
                    {option_user.label}
                  </MenuItem>
                ))}
              </Select>
            </C.InputAndLabel>
          </C.MediumInput>
          <C.MediumInput>
            <C.InputAndLabel>
              <label>Senha de Acesso</label>
              <TextField
                fullWidth={true}
                size="small"
                id="outlined-basic"
                variant="outlined"
                type="password"
                value={dataUser?.password}
                onChange={(e) => {
                  setDataUser({
                    ...dataUser,
                    password: e.target.value,
                  });
                }}
              />
            </C.InputAndLabel>
          </C.MediumInput>
          <C.ContainerButtons>
            <C.ContainerButton>
              <ButtonDark text="Salvar" funcao={handleSendData} />
            </C.ContainerButton>
            <C.ContainerButton>
              <ButtonOutlined text="Cancelar" funcao={handleGetUsers} />
            </C.ContainerButton>
          </C.ContainerButtons>
        </C.Form>
      </C.Content>
    </C.Container>
  );
}
