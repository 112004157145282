import * as C from "./styles";
import { DownloadCloud } from "react-feather";

export default function ButtonGreen({ text, route, funcao, disabled }) {
  return (
    <C.ButtonGreen
      disabled={disabled}
      onClick={() => {
        if (funcao) {
          funcao();
        }
      }}
    >
      <DownloadCloud width={20} height={20} />
      <C.ButtonInfo>{text}</C.ButtonInfo>
    </C.ButtonGreen>
  );
}
