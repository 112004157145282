export const saveToken = (token) => {
  localStorage.setItem("token", token);
};

export const getToken = () => {
  return localStorage.getItem("token");
};

export const setIsLogin = (data) => {
  return localStorage.setItem('user', JSON.stringify(data))
}

export const getIsLogin = () => {
  return JSON.parse(localStorage.getItem('user'))
}

export const setFirstLogin = (data) => {
  localStorage.setItem('FirstLogin', data)
}

export const getFirstLogin = () => {
  return localStorage.getItem('FirstLogin')
} 
