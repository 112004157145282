import * as React from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import Datasources from "../Datasources";
import Querys from "../Querys";
export default function TabsUserPermissions() {
  const [value, setValue] = React.useState(1);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <>
      <Box sx={{ width: "100%" }}>
        <Tabs value={value} onChange={handleChange}>
          <Tab
            value={1}
            label="Datasources"
            style={{
              fontWeight: "bold",
              textTransform: "initial",
              fontSize: "16px",
            }}
          />
          <Tab
            value={2}
            label="Consultas"
            style={{
              fontWeight: "bold",
              textTransform: "initial",
              fontSize: "16px",
            }}
          />
        </Tabs>
      </Box>
      {value === 1 ? <Datasources /> : value === 2 ? <Querys /> : ""}
    </>
  );
}
