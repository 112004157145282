import * as C from "./styles";
import React, { useEffect, useState } from "react";
import ButtonDark from "../../components/ButtonDark";
import { StateGlobal } from "../../context/GlobalContext";
import { MoreVertical, Edit2, User, Key, Trash } from "react-feather";
import {
  CircularProgress,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  IconButton,
  MenuItem,
  Menu,
} from "@mui/material";
import { PhoneMask } from '../../components/Masks'
import api from "../../service/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function GetUsers() {
  const [isLoading, setIsLoading] = useState(true);
  const { setComponents, setDataUser, dataUser } = StateGlobal();
  const [dataList, setDataList] = useState([]);
  const MySwal = withReactContent(Swal);

  async function getData() {
    setIsLoading(true);
    try {
      const res = await api.get("api/user/admin");
      setDataList(res.data);

      setIsLoading(false);
    } catch (err) {
      setIsLoading(true);
      console.log(err);
    }
  }

  useEffect(() => {
    getData();
  }, []);

  async function deleteUser() {
    setIsLoading(true);
    try {
      const res = await api.delete(`api/user/admin/delete/${dataUser.id}`);
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      if (res.data.error === "false") {
        handleClose();
        getData();
      }
    } catch (err) {
      setIsLoading(true);
      console.log(err);
    }
  }

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  function handleCreateUser() {
    setComponents(3);
    setDataUser({
      id: null,
      name: "",
      company_name: "",
      user_type: "",
      phone: "",
      email: "",
      password: "",
    });
  }

  function handleUserPermissions() {
    setComponents(4);
  }

  function handleToken() {
    setComponents(5);
  }

  function handleEditUser() {
    setComponents(6);
  }

  return (
    <C.Container>
      <C.ButtonAndTitle>
        <C.Title>Usuários de acesso</C.Title>
        <C.ContainerButton>
          <ButtonDark text="Novo Usuário" funcao={handleCreateUser} />
        </C.ContainerButton>
      </C.ButtonAndTitle>
      <C.Content>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 650 }} size="small">
            {isLoading === true ? (
              <C.DivLoading>
                <CircularProgress
                  style={{
                    width: "35px",
                    height: "35px",
                    color: "#0F2554",
                  }}
                />
              </C.DivLoading>
            ) : (
              <>
                <TableHead>
                  <TableRow style={{ backgroundColor: "#F3F2F7" }}>
                    <TableCell align="center">Empresa</TableCell>
                    <TableCell align="center">Nome</TableCell>
                    <TableCell align="center">E-mail</TableCell>
                    <TableCell align="center">Telefone</TableCell>
                    <TableCell align="center">Tipo de Acesso</TableCell>
                    <TableCell align="center">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {dataList.map((row) => (
                    <TableRow
                      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      key={row.name}
                    >
                      <TableCell align="center">{row.company_name}</TableCell>
                      <TableCell align="center">{row.name}</TableCell>
                      <TableCell align="center">{row.email}</TableCell>
                      <TableCell align="center">{row.phone ? PhoneMask(row.phone) : row.phone}</TableCell>
                      <TableCell align="center">{row.user_type}</TableCell>
                      <TableCell align="center">
                        <div>
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-controls={open ? "long-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={handleClick}
                          >
                            <MoreVertical
                              strokeWidth={1}
                              onClick={() => {
                                setDataUser({
                                  id: row.id,
                                  name: row.name,
                                  company_name: row.company_name,
                                  user_type: row.user_type,
                                  phone: row.phone,
                                  email: row.email,
                                });
                              }}
                            />
                          </IconButton>
                          <Menu
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            anchorOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "left",
                            }}
                          >
                            <MenuItem
                              onClick={() => {
                                handleEditUser();
                              }}
                            >
                              <Edit2
                                width={20}
                                style={{ marginRight: "10px" }}
                              />
                              Editar Usuário
                            </MenuItem>
                            <MenuItem onClick={handleUserPermissions}>
                              <User
                                width={20}
                                style={{ marginRight: "10px" }}
                              />
                              Permissões de Acesso
                            </MenuItem>
                            <MenuItem onClick={() => handleToken()}>
                              <Key width={20} style={{ marginRight: "10px" }} />
                              Tokens de Acesso API
                            </MenuItem>
                            <MenuItem
                              onClick={() => {
                                deleteUser();
                              }}
                            >
                              <Trash
                                width={20}
                                style={{ marginRight: "10px" }}
                              />
                              Excluir Usuário
                            </MenuItem>
                          </Menu>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </>
            )}
          </Table>
        </TableContainer>
      </C.Content>
    </C.Container>
  );
}
