import * as C from "./styles";
import { useEffect } from "react";
import {
  CircularProgress,
  Paper,
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableHead,
  TableContainer,
  IconButton,
  MenuItem,
  Menu,
  Select,
} from "@mui/material";
import React, { useState } from "react";
import ButtonDark from "../ButtonDark";
import { MoreVertical, Trash } from "react-feather";
import { StateGlobal } from "../../context/GlobalContext";
import api from "../../service/api";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Datasources() {
  const MySwal = withReactContent(Swal);

  const [isLoading, setIsLoading] = useState(false);
  const [datasource, setDatasource] = useState("");
  const [options_datasource, setOptions_datasource] = useState([]);
  const [datasource_permissions, setDatasource_permissions] = useState([]);
  const [block_button, setBlock_button] = useState(true);


  const [anchorEl, setAnchorEl] = React.useState(null);
  const { dataUser } = StateGlobal();
  const open = Boolean(anchorEl);

  const [permission_id, setPermission_id] = useState("");


  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  async function handleGetPermissions() {
    try {
      setIsLoading(true);
      const res = await api.post("/api/user/admin/permission/datasources", {
        user_id: dataUser.id,
      });
      setDatasource_permissions(res.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleDeletePermission() {
    try {
      setIsLoading(true);
      const res = await api.delete(
        `/api/user/admin/permission/datasources/delete/${dataUser.id}/${permission_id}`
      );
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      handleClose();
      setIsLoading(false);
      handleGetPermissions();
    } catch (err) {
      console.log(err);
    }
  }

  async function handleGetDatasourcesSelect() {
    try {
      setIsLoading(true);
      const res = await api.get("/api/user/admin/permission/datasources/show");
      setOptions_datasource(res.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  }

  async function handleAddPermission() {
    try {
      setIsLoading(true);
      const res = await api.post(
        "/api/user/admin/permission/datasources/register",
        {
          user_id: dataUser.id,
          datasource_id: datasource,
        }
      );
      MySwal.fire({
        heightAuto: false,
        position: "center",
        icon: res.data.error === "false" ? "success" : "error",
        title: res.data.message,
        showConfirmButton: false,
        timer: 2000,
      });
      setIsLoading(false);
      handleGetPermissions();
      if (res.data.error === "false") {
        setDatasource("");
      }
    } catch (err) {
      console.log(err);
    }
  }

  function VerifyDisabled() {
    if (datasource !== "") {
      setBlock_button(false);
    } else {
      setBlock_button(true);
    }
  }

  useEffect(() => {
    VerifyDisabled();
  }, [datasource]);

  useEffect(() => {
    handleGetPermissions();
    handleGetDatasourcesSelect();
  }, []);

  return (
    <C.Content>
      <C.DivRow>
        <C.MediumInput>
          <Select
            size="small"
            style={{ width: "500px" }}
            value={datasource}
            onChange={(e) => setDatasource(e.target.value)}
          >
            {options_datasource.map((options_datasource) => (
              <MenuItem
                key={options_datasource.id}
                value={options_datasource.id}
              >
                {options_datasource.name}
              </MenuItem>
            ))}
          </Select>
        </C.MediumInput>
        <C.ContainerButton>
          <ButtonDark
            text="Adicionar"
            funcao={handleAddPermission}
            disabled={block_button}
          />
        </C.ContainerButton>
      </C.DivRow>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} size="small">
          {isLoading === true ? (
            <C.DivLoading>
              <CircularProgress
                style={{
                  width: "35px",
                  height: "35px",
                  color: "#0F2554",
                }}
              />
            </C.DivLoading>
          ) : (
            <>
              <TableHead>
                <TableRow style={{ backgroundColor: "#F3F2F7" }}>
                  <TableCell align="center">Datasource</TableCell>
                  <TableCell align="center">Ações</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {datasource_permissions.map((row) => (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                    key={row.id}
                  >
                    <TableCell align="center">{row.name}</TableCell>
                    <TableCell align="center">
                      {" "}
                      <div>
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-controls={open ? "long-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-haspopup="true"
                          onClick={handleClick}
                        >
                          <MoreVertical
                            strokeWidth={1}
                            onClick={() => setPermission_id(row.id)}
                          />
                        </IconButton>
                        <Menu
                          anchorEl={anchorEl}
                          open={open}
                          anchorOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                          transformOrigin={{
                            vertical: "top",
                            horizontal: "left",
                          }}
                        >
                          <MenuItem onClick={() => handleDeletePermission(row)}>
                            <Trash width={20} style={{ marginRight: "10px" }} />
                            Excluir
                          </MenuItem>
                        </Menu>
                      </div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </>
          )}
        </Table>
      </TableContainer>
    </C.Content>
  );
}
