import * as C from "./styles";
import { useState } from 'react'
import LeftMenu from "../../components/LeftMenu";
import Companies from "../Companies";
import Null from "../Null";
import GetUsers from "../GetUsers";
import CreateUsers from "../CreateUsers";
import UserPermissions from "../UserPermissions";
import { StateGlobal } from "../../context/GlobalContext";
import AccessTokens from "../AccessTokens";
import EditUsers from "../EditUsers";
import { useNavigate } from "react-router-dom";
import api from "../../service/api";
import { getFirstLogin, setFirstLogin, setIsLogin } from "../../service/auth";
import { useEffect } from "react";

export default function Home() {
  const { components, setComponents } = StateGlobal();
  const navigate = useNavigate();
  const [name_user, setName_user] = useState('')

  async function getData() {
    try {
      const res = await api.get("api/user");
      setIsLogin(res.data);
      setName_user(res.data.name)
    } catch (err) {
      console.log(err);
    }
  }

  async function handleLogout() {
    try {
      const res = await api.post("/api/user/logout");
      if (res.data.error === "false") {
        setIsLogin('');
        navigate("/");
        setComponents(0);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    const login = getFirstLogin()
    if (login === true || login === 'true') {
      setFirstLogin(false)
      document.location.reload(true)
    }
  }, [])

  useEffect(() => {
    getData();
  }, []);

  console.log('name_user', name_user)


  return (
    <C.Container>
      <LeftMenu name_user={name_user} />
      <C.Icon onClick={() => handleLogout()}>Logout</C.Icon>
      {components === 0 ? (
        <Null />
      ) : components === 1 ? (
        <Companies />
      ) : components === 2 ? (
        <GetUsers />
      ) : components === 3 ? (
        <CreateUsers />
      ) : components === 4 ? (
        <UserPermissions />
      ) : components === 5 ? (
        <AccessTokens />
      ) : components === 6 ? (
        <EditUsers />
      ) : (
        ""
      )}
    </C.Container>
  );
}
