import * as C from "./styles";
import TabsUserPermissions from "../../components/TabsUserPermissions";

export default function UserPermissions() {
  return (
    <C.Container>
      <C.ButtonAndTitle>
        <C.Title>Permissões</C.Title>
      </C.ButtonAndTitle>
      <TabsUserPermissions />
    </C.Container>
  );
}
