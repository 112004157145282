import React, { createContext, useState, useContext } from "react";

export const GlobalContext = createContext({});

export default function CountProvider({ children }) {
  const [components, setComponents] = useState(0);
  const [dataUser, setDataUser] = useState({
    id: null,
    name: "",
    company_name: "",
    user_type: "",
    phone: "",
    email: "",
    password: "",
  });
  const [info_query, setInfo_query] = useState([]);
  const [user_permissions, setUser_permissions] = useState([]);
  const [body_query, setBody_query] = useState({});

  return (
    <GlobalContext.Provider
      value={{
        components,
        setComponents,
        dataUser,
        setDataUser,
        info_query,
        setInfo_query,
        user_permissions,
        setUser_permissions,
        body_query,
        setBody_query,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
}

export function StateGlobal() {
  const context = useContext(GlobalContext);
  const { components, setComponents } = context;
  const { dataUser, setDataUser } = context;
  const { info_query, setInfo_query } = context;
  const { user_permissions, setUser_permissions } = context;
  const { body_query, setBody_query } = context;

  return {
    components,
    setComponents,
    dataUser,
    setDataUser,
    info_query,
    setInfo_query,
    user_permissions,
    setUser_permissions,
    body_query,
    setBody_query,
  };
}
