import SelectSearch from "react-select";
import { StateGlobal } from "../../context/GlobalContext";

export function InputSelect({ slug, defaultValue, options, disabled }) {
  const { body_query, setBody_query } = StateGlobal();

  return (
    <SelectSearch
      // defaultInputValue={body_query[slug] }
      options={options}
      disabled={disabled}
      onChange={(e) =>
        setBody_query({
          ...body_query,
          [slug]: e.value,
        })
      }
      placeholder=""
    />
  );
}
