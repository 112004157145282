import { TextField } from "@mui/material";
import { StateGlobal } from "../../context/GlobalContext";

export function InputText({ slug, disabled }) {
  const { body_query, setBody_query } = StateGlobal();

  return (
    <TextField
      size="small"
      value={body_query[slug] ? body_query[slug] : ""}
      onChange={(e) => setBody_query({ ...body_query, [slug]: e.target.value })}
    />
  );
}
