import styled from "styled-components";

export const ButtonGreen = styled.button`
  background-color: #14ae5c;
  border: none;
  border-radius: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 15px;
  cursor: pointer;
`;

export const ButtonInfo = styled.div`
  color: #000;
  font-weight: 600;
  font-size: 14px;
  margin-left: 5px;
`;
